<template>
    <div class="remove__list">
        <span>
            Pick a list, chat or shortcut on the left 
        </span>
    </div>
</template>

<script>

    export default {
        
    }

</script>